<template>
    <div>
        <b-card no-body>
            <div class="p-1" v-if="currentUser.roles[0].name === 'super_admin'">
                <div class="d-flex align-items-center justify-content-end">
                    <b-link
                        :to="{ name: 'createMaintenance', params: { project_id: project_id, client_id: client_id } }">
                        <b-button variant="primary" size="sm" class="mr-1 text-nowrap">
                            {{ '+ Añadir mantenimiento' }}
                        </b-button>
                    </b-link>
                </div>
            </div>
            <hr class="m-0" />
            <!-- filtros -->
            <div class="p-1">
                <div class="col-12">
                    <b-row>
                        <b-col class="col-11"></b-col>
                        <b-col class="col-1 text-right">
                            <span class="cursor-pointer" @click="visibleFilter = !visibleFilter">
                                <feather-icon icon="FilterIcon" size="20" />
                            </span>
                        </b-col>
                    </b-row>
                </div>
                <b-collapse id="collapse" v-model="visibleFilter" class="mt-2">
                    <div class="px-2">
                        <b-row>

                            <b-col sm="4">
                                <b-form-group label-for="filter-status">
                                    <v-select v-model="status" label="name" :filterable="true" :searchable="true"
                                        :options="selectStatus" :placeholder="'Filtrar por estado'" />
                                </b-form-group>
                            </b-col>

                            <b-col sm="4">
                                <flat-pickr v-model="date" name="date" class="form-control" :config="dateConfig"
                                    :placeholder="'Filtrar por fecha'" />
                            </b-col>

                            <b-col sm="4">
                                <div class="d-flex justify-content-start align-items-center">
                                    <b-form-input v-model="searchTerm" :placeholder="$t('Busqueda')" type="text"
                                        class="d-inline-block w-auto" />
                                    <b-button class="ml-1" @click="handleSearch" variant="primary">
                                        {{ $t("Buscar") }}
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </b-collapse>
            </div>
            <!-- tabla -->
            <b-table hover responsive :items="maintenances" :fields="columns" class="table-white">
                <template #cell(name)="data">
                    <b-link :to="{ name: 'viewMaintenance', params: { id: data.item.id } }" class="link">
                        {{ data.item.name }}
                    </b-link>
                </template>
                <template #cell(date)="data">
                    <span :class="(data.item.expired) ? 'text-red' : ''">{{ dateColumn(data.item.date) }}</span>
                </template>
                <template #cell(frequency_type)="data">
                    <span>{{ frequencyText[data.item.frequency_type] }}</span>
                </template>
                <template #cell(user)="data">
                    <b-link v-if="data.item.user" :to="{ name: 'viewUser', params: { id: data.item.user.id } }"
                        class="link">
                        {{ data.item.user.name }} {{ data.item.user.surname }}
                    </b-link>
                </template>
                <template #cell(status)="data">
                    <div class="d-flex">
                        <b-badge :variant="statusMaintenanceVariants[data.item.status.alias]">
                            {{ data.item.status.name }}
                        </b-badge>
                        <b-badge v-if="(data.item.inicidence == 1)" variant="light-warning" style="margin-left: 5px;">
                            <feather-icon icon="AlertTriangleIcon" size="22" />
                        </b-badge>
                    </div>
                </template>
                <template #cell(actions)="data">
                    <span>
                        <b-link :to="{ name: 'viewMaintenance', params: { id: data.item.id } }"
                            class="d-inline-block px-50 text-dark">
                            <feather-icon icon="EyeIcon" />
                        </b-link>
                        <b-link v-if="currentUser.roles[0].name === 'super_admin'"
                            :to="{ name: 'editMaintenance', params: { id: data.item.id } }"
                            class="d-inline-block px-50 text-dark">
                            <feather-icon icon="Edit2Icon" />
                        </b-link>
                    </span>
                </template>
            </b-table>
            <!-- paginador  -->
            <div v-if="totalItems > pageLength" class="card-footer list-footer d-flex align-items-center justify-content-between mb-1 px-2">
                <div class="d-flex align-items-center mb-0">
                    <span class="text-nowrap ">
                        {{ 'Por página' }}:
                    </span>
                    <b-form-select v-model="pageLength" :options="pages" class="ml-50 mr-1" @input="handlePageChange" />
                    <span class="text-nowrap"> {{ 'Total' }}: {{ totalItems }}</span>
                </div>
                <div>
                    <b-pagination v-model="currentPage" :total-rows="totalItems" :per-page="pageLength" first-number
                        last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                        @change="handleChangePage">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </b-card>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
    BCard, BTable, BLink, BButton, BFormSelect, BPagination, BBadge, BCollapse, BFormInput, BCol, BRow, BFormGroup
} from "bootstrap-vue";

import store from "@/store/index";
import { config } from "@/shared/app.config";
import vSelect from "vue-select";

import flatPickr from 'vue-flatpickr-component'
import { FlatPickrToTimestamp, TimestampToFlatPickr } from '@/libs/helpers'

export default {
    components: {
        BTable,
        BCard,
        BLink,
        BButton,
        BFormSelect,
        BFormGroup,
        BPagination,
        BBadge,
        BCollapse,
        BFormInput,
        BCol,
        BRow,
        vSelect,
        flatPickr
    },
    props: {
        project_id: {
            type: Number,
            default: 0,
        },
        client_id: {
            type: Number,
            default: 0,
        }
    },
    data() {
        return {
            currentPage: 1,
            pageLength: 10,
            pages: ['10', '15', '25'],
            searchTerm: '',
            columns: [
                {
                    label: 'Nombre',
                    key: 'name',
                },
                {
                    label: 'Fecha',
                    key: 'date',
                },
                {
                    label: 'Frecuencia',
                    key: 'frequency_type',
                },
                {
                    label: 'Técnico',
                    key: 'user',
                },
                {
                    label: 'Estado',
                    key: 'status',
                },
                {
                    label: 'Acciones',
                    key: 'actions',
                    class: 'column-actions',
                }
            ],
            status: '',
            frequencyText: config.frequencyText,
            statusMaintenanceVariants: config.statusMaintenanceVariants,
            visibleFilter: false,
            date: '',
            dateConfig: {
                mode: "range"

            },
            date_ini: '',
            date_fin: ''
        };
    },
    computed: {
        ...mapGetters({
            // client: "clients/getClient",
            currentUser: 'auth/getUser',
            asset: 'assets/getAsset',
            maintenances: 'maintenances/getItems',
            totalItems: 'maintenances/getTotalItems',
            selectStatus: 'maintenances/getSelectStatus',

        })
    },
    async created() {
        // console.log('cargar asset...', this)
        // await this.getAsset(this.asset_id)
        await this.chargeData();

        await this.getSelectStatus({
            type: "maintenance"
        })
    },
    methods: {
        ...mapActions({
            // getClient: "clients/getClient",
            getAsset: 'assets/getAsset',
            getListMaintenances: 'maintenances/getListMaintenances',
            getSelectStatus: 'maintenances/getSelectStatus',
            delete: 'maintenances/delete',
        }),
        dateColumn(time) {
            return TimestampToFlatPickr(time)
        },
        async chargeData() {

            // let client = this.currentUser.clients.length > 0 ? this.currentUser.clients[0].id : ''
            let status = this.status ? this.status.id : ''

            this.getListMaintenances({
                page: this.currentPage,
                per_page: this.pageLength,
                search: this.searchTerm,
                project_id: this.project_id,
                status_id: status,
                // id_client: client,
                date_ini: this.date_ini,
                date_fin: this.date_fin
            })
        },
        handleSearch() {
            this.currentPage = 1;
            this.chargeData();
        },
        handleChangePage(page) {
            this.currentPage = page;
            this.chargeData();
        },
        handlePageChange() {
            this.currentPage = 1;
            this.chargeData();
        },
        onSortChange(params) {
            this.currentPage = 1;
            console.log(params);
            this.chargeData();
        },
        deleteMaintenance(location, name) {
            this.$bvModal
                .msgBoxConfirm(`¿Estás seguro de eliminar ${name}?`, {
                    bodyClass: 'bodyDelete',
                    footerClass: 'footerDelete',
                    okVariant: 'danger',
                    okTitle: 'Eliminar',
                    cancelTitle: 'Cancelar',
                    cancelVariant: 'outline-danger',
                    centered: true,
                })
                .then(value => {
                    if (value === true) {
                        this.delete(location)
                    }
                })
        },
    },
    watch: {
        date() {

            let range = this.date.split(' a ')

            this.date_ini = FlatPickrToTimestamp(range[0]);
            if (range.length > 1) {
                this.date_fin = FlatPickrToTimestamp(range[1]);
            } else {
                this.date_fin = '';
            }

            this.currentPage = 1;
            this.chargeData();

        },
        sortBy() {
            this.currentPage = 1;
            this.chargeData();
        },
        sortDesc() {
            this.currentPage = 1;
            this.chargeData();
        },
        orderby() {
            this.currentPage = 1;
            this.chargeData();
        },
        status() {
            this.currentPage = 1;
            this.chargeData();
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.text-red {
    color: red !important
}
</style>
