<template>
    <div>
        <b-card no-body>
            <div class="p-1" v-if="currentUser.roles[0].name === 'super_admin'">
                <div class="d-flex align-items-center justify-content-end">
                    <b-link :to="{ name: 'createLocation', params: { project_id: project_id } }">
                        <b-button variant="primary" size="sm" class="mr-1 text-nowrap">
                            {{ '+ Añadir zona' }}
                        </b-button>
                    </b-link>
                </div>
            </div>
            <!-- tabla -->
            <b-table hover responsive :items="zones" :fields="columns" class="table-white">
                <template #cell(actions)="data">
                    <span>
                        <b-link :to="{ name: 'viewLocation', params: { id: data.item.id } }"
                            class="d-inline-block px-50 text-dark">
                            <feather-icon icon="EyeIcon" />
                        </b-link>
                        <b-link v-if="currentUser.roles[0].name === 'super_admin'"
                            :to="{ name: 'editLocation', params: { id: data.item.id } }"
                            class="d-inline-block px-50 text-dark">
                            <feather-icon icon="Edit2Icon" />
                        </b-link>
                        <span v-if="currentUser.roles[0].name === 'super_admin'"
                            @click="deleteLocation(data.item, data.item.name)" class="d-inline-block px-50 text-danger">
                            <feather-icon icon="TrashIcon" />
                        </span>
                    </span>
                </template>
            </b-table>
            <!-- paginador  -->
            <div v-if="totalItems > pageLength" class="card-footer list-footer d-flex align-items-center justify-content-between mb-1 px-2">
                <div class="d-flex align-items-center mb-0">
                    <span class="text-nowrap ">
                        {{ 'Por página' }}:
                    </span>
                    <b-form-select v-model="pageLength" :options="pages" class="ml-50 mr-1" @input="handlePageChange" />
                    <span class="text-nowrap"> {{ 'Total' }}: {{ totalItems }}</span>
                </div>
                <div>
                    <b-pagination v-model="currentPage" :total-rows="totalItems" :per-page="pageLength" first-number
                        last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                        @change="handleChangePage">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </b-card>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
    BCard,
    BTable,
    BLink,
    BButton,
    BFormSelect,
    BPagination
} from "bootstrap-vue";

import store from "@/store/index";
import { config } from "@/shared/app.config";

export default {
    components: {
        BTable,
        BCard,
        BLink,
        BButton,
        BFormSelect,
        BPagination
    },
    props: {
        client_id: {
            type: Number,
            default: 0,
        },
        project_id: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            currentPage: 1,
            pageLength: 10,
            pages: ['10', '15', '25'],
            searchTerm: '',
            columns: [
                {
                    label: 'Nombre de la Zona',
                    key: 'name',
                },
                {
                    label: 'Acciones',
                    key: 'actions',
                    class: 'column-actions',
                }
            ]
        };
    },
    computed: {
        ...mapGetters({
            client: "clients/getClient",
            zones: "locations/getZones",
            totalItems: 'locations/getTotalItems',
            currentUser: 'auth/getUser',
        })
    },
    async created() {
        await this.chargeData();
    },
    methods: {
        ...mapActions({
            getClient: "clients/getClient",
            getZones: "locations/getListLocations",
            delete: 'locations/delete',
        }),
        async chargeData() {
            //cargando cliente
            // this.getClient(this.client_id);
            //cargando listado de zonas
            this.getZones({
                client: this.client_id,
                project: this.project_id,
                page: this.currentPage,
                per_page: this.pageLength,
                search: this.searchTerm,

            })
        },
        handleSearch() {
            this.currentPage = 1;
            this.chargeData();
        },
        handleChangePage(page) {
            this.currentPage = page;
            this.chargeData();
        },
        handlePageChange() {
            this.currentPage = 1;
            this.chargeData();
        },
        onSortChange(params) {
            this.currentPage = 1;
            console.log(params);
            this.chargeData();
        },
        deleteLocation(location, name) {
            this.$bvModal
                .msgBoxConfirm(`¿Estás seguro de eliminar ${name}?`, {
                    bodyClass: 'bodyDelete',
                    footerClass: 'footerDelete',
                    okVariant: 'danger',
                    okTitle: 'Eliminar',
                    cancelTitle: 'Cancelar',
                    cancelVariant: 'outline-danger',
                    centered: true,
                })
                .then(value => {
                    if (value === true) {
                        this.delete(location)
                    }
                })
        },
    },
};
</script>
