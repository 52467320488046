<template>
  <div>
    <b-card no-body>
      <!-- create -->
      <div class="p-1">
        <div
          v-if="currentUser.roles[0].name === 'super_admin'"
          class="d-flex align-items-center justify-content-end"
        >
          <b-link
            v-if="project.locations.length > 0"
            :to="{ name: 'createAsset', params: { project_id: project_id } }"
          >
            <b-button
              variant="primary"
              size="sm"
              class="mr-1 text-nowrap"
            >
              {{ '+ Añadir Equipo' }}
            </b-button>
          </b-link>
        </div>
      </div>
      <hr class="m-0">
      <!-- filters -->
      <div class="p-1">
        <div class="col-12">
          <b-row>
            <b-col class="col-11" />
            <b-col class="col-1 text-right">
              <span
                class="cursor-pointer"
                @click="visibleFilter = !visibleFilter"
              >
                <feather-icon
                  icon="FilterIcon"
                  size="20"
                />
              </span>
            </b-col>
          </b-row>
        </div>
        <b-collapse
          id="collapse"
          v-model="visibleFilter"
          class="mt-2"
        >
          <div class="px-2">
            <b-row>
              <b-col cols="4">
                <b-form-group label-for="filter-category">
                  <v-select
                    v-model="category"
                    label="name"
                    :filterable="false"
                    :searchable="false"
                    :options="selectCategories"
                    :placeholder="'Filtrar por Categoria'"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label-for="filter-status">
                  <v-select
                    v-model="status"
                    label="name"
                    :filterable="false"
                    :searchable="false"
                    :options="selectStatus"
                    :placeholder="'Filtrar por Estado'"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label-for="filter-zones">
                  <v-select
                    v-model="zone"
                    label="name"
                    :filterable="false"
                    :searchable="false"
                    :options="selectZones"
                    :placeholder="'Filtrar por Zona'"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <div class="d-flex justify-content-start align-items-center">
                  <b-form-input
                    v-model="searchTerm"
                    :placeholder="$t('Busqueda')"
                    type="text"
                    class="d-inline-block w-50"
                  />
                  <b-button
                    class="ml-1"
                    variant="primary"
                    @click="handleSearch"
                  >
                    {{ $t("Buscar") }}
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-collapse>
      </div>

      <!-- tabla -->
      <b-table
        hover
        responsive
        :items="assets"
        :fields="columns"
        class="table-white"
      >
        <template #cell(product.name)="data">
          <b-link
            :to="{ name: 'viewAsset', params: { id: data.item.id } }"
            class="link"
          >
            {{ data.item.name }}
          </b-link>
        </template>
        <template #cell(ini)="data">
          <span v-if="data.item.product.type.includes('cortina')">
            {{ data.item.ini }}
          </span>
        </template>
        <template #cell(last_review_date)="data">
          <span>{{ dateColumn(data.item.last_review_date) }}</span>
        </template>
        <template #cell(next_review_date)="data">
          <span>{{ dateColumn(data.item.next_review_date) }}</span>
        </template>
        <template #cell(status)="data">
          <b-badge
            class="d-flex justify-content-center align-items-center position-relative"
            :variant="statusAssetVariants[data.item.status.alias]"
            style="top: -3px"
          >
            <feather-icon :icon="statusAssetIcons[data.item.status.alias]" />&nbsp;{{
              data.item.status.name
            }}
          </b-badge>
        </template>
        <template #cell(actions)="data">
          <span>
            <b-link
              :to="{ name: 'viewAsset', params: { id: data.item.id } }"
              class="d-inline-block px-50 text-dark"
            >
              <feather-icon icon="EyeIcon" />
            </b-link>
            <b-link
              v-if="currentUser.roles[0].name === 'super_admin'"
              :to="{ name: 'editAsset', params: { id: data.item.id } }"
              class="d-inline-block px-50 text-dark"
            >
              <feather-icon icon="Edit2Icon" />
            </b-link>
            <span
              class="d-inline-block px-50 text-danger"
              @click="deleteAsset(data.item.id, data.item.name)"
            >
              <feather-icon icon="TrashIcon" />
            </span>
          </span>
        </template>
      </b-table>
      <!-- paginador  -->
      <div
        v-if="totalItems > pageLength"
        class="card-footer list-footer d-flex align-items-center justify-content-between mb-1 px-2"
      >
        <div class="d-flex align-items-center mb-0">
          <span class="text-nowrap ">
            {{ 'Por página' }}:
          </span>
          <b-form-select
            v-model="pageLength"
            :options="pages"
            class="ml-50 mr-1"
            @input="handlePageChange"
          />
          <span class="text-nowrap"> {{ 'Total' }}: {{ totalItems }}</span>
        </div>
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalItems"
            :per-page="pageLength"
            first-number
            last-number
            align="right"
            prev-class="prev-item"
            next-class="next-item"
            class="mt-1 mb-0"
            @change="handleChangePage"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BCard,
  BTable,
  BLink,
  BButton,
  BCol,
  BRow,
  BFormSelect,
  BPagination,
  BBadge,
  BFormGroup,
  BFormInput,
  BCollapse,

} from 'bootstrap-vue'
import { config } from '@/shared/app.config'
import vSelect from 'vue-select'
import { TimestampToFlatPickr } from '@/libs/helpers'

export default {
  components: {
    BTable,
    BCard,
    BLink,
    BCol,
    BRow,
    BButton,
    BFormSelect,
    BFormInput,
    BPagination,
    BFormGroup,
    BBadge,
    vSelect,
    TimestampToFlatPickr,
    BCollapse,
  },
  props: {
    client_id: {
      type: Number,
      default: 0,
    },
    project_id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      currentPage: 1,
      pageLength: 10,
      pages: ['10', '15', '25'],
      searchTerm: '',
      columns: [
        {
          label: 'Nombre',
          key: 'product.name',
        },
        {
          label: 'Cod. INI',
          key: 'ini',
        },
        {
          label: 'Zona',
          key: 'location.name',
        },
        {
          label: 'Último mantenimiento',
          key: 'last_review_date',
        },
        {
          label: 'Próximo mantenimiento',
          key: 'next_review_date',
        },
        {
          label: 'Estado',
          key: 'status',
        },
        {
          label: 'Acciones',
          key: 'actions',
          class: 'column-actions',
        },
      ],
      status: '',
      statusAssetVariants: config.statusAssetVariants,
      statusAssetIcons: config.statusAssetIcons,
      zone: '',
      category: '',
      visibleFilter: false,

    }
  },
  computed: {
    ...mapGetters({
      client: 'clients/getClient',
      assets: 'assets/getAssets',
      totalItems: 'assets/getTotalItems',
      selectStatus: 'orders/getSelectStatus',
      selectZones: 'locations/getSelectLocations',
      selectCategories: 'categories/getSelectCategories',
      project: 'projects/getProject',
      currentUser: 'auth/getUser',

    }),
  },
  watch: {
    status() {
      this.currentPage = 1
      this.chargeData()
    },
    category() {
      this.currentPage = 1
      this.chargeData()
    },
    zone() {
      this.currentPage = 1
      this.chargeData()
    },
  },
  async created() {
    await this.chargeData()
    await this.getProject(this.project_id)
    await this.getSelectStatus({ type: 'asset' })
    await this.getSelectCategories({ parent: '', only_children: false })
    await this.getSelectLocations({
      client: this.client_id,
      project: this.project_id,
    })
  },
  methods: {
    ...mapActions({
      getClient: 'clients/getClient',
      getAssets: 'assets/getListAssets',
      getSelectStatus: 'orders/getSelectStatus',
      getSelectLocations: 'locations/selectLocations',
      getSelectCategories: 'categories/getSelectCategories',
      getProject: 'projects/getProject',
      unassign: 'assets/unassign',
    }),
    dateColumn(time) {
      return TimestampToFlatPickr(time)
    },
    async chargeData() {
      const pageLength = this.pageLength ? this.pageLength : 1
      const status = this.status ? this.status.id : ''

      const location = this.zone ? this.zone.id : ''

      const category = this.category ? this.category.id : ''

      console.log('zone', location, this.zone)

      this.getAssets({
        id_client: this.client_id,
        project_id: this.project_id,
        page: this.currentPage,
        per_page: pageLength,
        status_id: status,
        category_id: category,
        location_id: location,
        search: this.searchTerm,
      })
    },
    handleSearch() {
      this.currentPage = 1
      this.chargeData()
    },
    handleChangePage(page) {
      this.currentPage = page
      this.chargeData()
    },
    handlePageChange() {
      this.currentPage = 1
      this.chargeData()
    },
    onSortChange(params) {
      this.currentPage = 1
      console.log(params)
      this.chargeData()
    },
    deleteAsset(id, name) {
      console.log(id)
      this.$bvModal
        .msgBoxConfirm(`¿Estás seguro de quitar del proyecto ${name}?`, {
          bodyClass: 'bodyDelete',
          footerClass: 'footerDelete',
          okVariant: 'danger',
          okTitle: 'Eliminar',
          cancelTitle: 'Cancelar',
          cancelVariant: 'outline-danger',
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.unassign(id)
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
