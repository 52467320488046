<template>
  <div>
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder m-0 hGoBack">
        <span @click="$router.go(-1)">
          <b-button variant="white" class="btn-icon rounded-circle mr-50 text-danger bg-white" style="padding: 0.5px">
            <feather-icon icon="ArrowLeftCircleIcon" size="35" />
          </b-button>
        </span> Ficha de la Obra
      </h2>
    </div>
    <b-row v-if="projectPrepare">

      <b-col cols="12">
        <b-card class="height-card">
          <b-row>
            <b-col cols="12" class="text-right">
              <b-link v-if="currentUser.roles[0].name === 'super_admin'"
                :to="{ name: 'editProject', params: { id: project.id } }" class="d-inline-block btn-primary">
                <span class="">Editar</span>
                <span class=" pl-1">
                  <feather-icon icon="Edit2Icon" />
                </span>
              </b-link>
              <hr />
            </b-col>
          </b-row>
          <b-row class="position-relative">

            <b-col cols="12">
              <p class="pTitle">Obra</p>
              <div class="d-flex">
                <h3 class="font-weight-bolder hTitle m-0">
                  {{ project.name }}
                </h3>
                <b-badge class="ml-1 d-flex align-items-center" :variant="statusProyectIcon[project.status.alias].show">
                  <feather-icon :icon="statusProyectIcon[project.status.alias].icon" size="14" />
                  &nbsp;{{ project.status.name }}
                </b-badge>
              </div>
            </b-col>

            <span :class="collapseInfo ? 'text-danger collapse-button' : 'text-danger collapse-button collapsed'"
              :aria-expanded="collapseInfo ? 'true' : 'false'" aria-controls="collapse-info"
              @click="collapseInfo = !collapseInfo">
              <feather-icon :icon="collapseInfo ? 'ChevronUpIcon' : 'ChevronDownIcon'" size="22" />
            </span>
            <b-col cols="12">
              <b-collapse id="collapse-info" v-model="collapseInfo" class="mt-2">
                <hr>
                <b-row>
                  <b-col v-if="project.client" xl="4" md="6" class="product_info">
                    <p class="m-0"><strong>Empresa:</strong></p>
                    <b-link :to="{ name: 'viewClient', params: { id: project.client.id } }">
                      {{ project.client.name }}
                    </b-link>
                  </b-col>

                  <b-col v-if="project.ref" xl="4" md="6" class="product_info">
                    <p class="m-0"><strong>Referencia:</strong></p>
                    <div v-html="project.ref"></div>
                  </b-col>

                  <b-col v-if="project.address" xl="4" md="6" class="product_info">
                    <p class="m-0"><strong>Dirección de la obra:</strong></p>
                    <div v-html="project.address"></div>
                  </b-col>

                  <b-col v-if="project.responsible" xl="4" md="6" class="product_info">
                    <p class="m-0"><strong>Responsable de la obra:</strong></p>
                    <b-link :to="{ name: 'viewUser', params: { id:  project.responsible.name.id } }" class="link">
                      {{ project.responsible.name }}
                      {{ project.responsible.surname }}
                    </b-link>
                  </b-col>

                  <b-col v-if="project.description" cols="12" class="product_info">
                    <p class="m-0"><strong>Descripción:</strong></p>
                    <div v-html="project.description"></div>
                  </b-col>

                </b-row>
              </b-collapse>
            </b-col>

          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12">
        <!-- tabs -->
        <b-tabs
          v-model="tabIndex"
          content-class="col-12 mt-1 mt-md-0"
          nav-wrapper-class="col-12"
          nav-class="nav-left"
        >
          <!-- equipo tab -->
          <b-tab>
            <template #title>
              <feather-icon icon="CpuIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Equipos</span>
            </template>
            <assets-table :client_id="project.client.id" :project_id="project.id"></assets-table>
          </b-tab>
          <!-- zonas tab -->
          <b-tab>
            <!-- title -->
            <template #title>
              <feather-icon icon="MapPinIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Zonas</span>
            </template>

            <locations-table :client_id="project.client.id" :project_id="project.id"></locations-table>
          </b-tab>
          <!-- documentos tab -->
          <b-tab>
            <!-- title -->
            <template #title>
              <feather-icon icon="ClipboardIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Documentos</span>
            </template>

            <b-card no-body>
              <DocumentsTable :parameters="documentsTableParameters" :view="'projects'" />
            </b-card>
          </b-tab>
          <!-- mantenimientos tab -->
          <b-tab>
            <!-- title -->
            <template #title>
              <feather-icon icon="ToolIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Mantenimientos</span>
            </template>
            <maintenances-table :project_id="project.id" :client_id="project.client.id"></maintenances-table>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BBadge, BRow, BCol, BCard, BCollapse, BLink, BButton, BTable, BPagination, BFormSelect, BFormGroup, BFormInput, BTabs,
  BTab, BForm
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { config } from '@/shared/app.config'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import AssetsTable from './AssetsTable.vue'
import LocationsTable from './LocationsTable.vue'
import MaintenancesTable from './MaintenancesTable.vue'
import ImageDropzone from '@/components/elements/ImageDropzone/ImageDropzone.vue'
import DocumentsTable from '@/components/documents/table/DocumentsTable.vue'

export default {
  components: {
    BButton,
    BBadge,
    BLink,
    BRow,
    BCol,
    BCard,
    BTable,
    BPagination,
    BForm,
    BFormSelect,
    BCollapse,
    BFormGroup,
    vSelect,
    flatPickr,
    BFormInput,
    BTabs,
    BTab,
    DocumentsTable,
    AssetsTable,
    LocationsTable,
    MaintenancesTable,
    ImageDropzone,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      sortBy: '',
      sortDesc: '',
      statusProyectIcon: config.statusProyectIcon,
      currentPage: 1,
      pageLength: 10,
      dir: false,
      collapseInfo: false,
      projectPrepare: false,
      pages: ['10', '15', '25'],
      files: [],
      status: '',
      searchTerm: '',
      visibleFilter: false,
      columns: [
        {
          label: 'Nombre',
          key: 'name',
        },
        {
          label: 'Acciones',
          key: 'actions',
          class: 'column-actions',
        },
      ],
      tabIndex: 0,
    }
  },
  watch: {
    sortBy() {
      this.currentPage = 1
      this.chargeData()
    },
    sortDesc() {
      this.currentPage = 1
      this.chargeData()
    },
    date_ini() {
      this.currentPage = 1
      this.chargeData()
    },
    date_fin() {
      this.currentPage = 1
      this.chargeData()
    },
    status() {
      this.currentPage = 1
      this.chargeData()
    },
  },
  computed: {
    ...mapGetters({
      project: 'projects/getProject',
      // items: 'jobs/getItems',
      // totalItems: 'jobs/getTotalItems',
      role: 'auth/getRole',
      selectStatus: 'projects/getSelectStatus',
      currentUser: 'auth/getUser',
    }),
    documentsTableParameters() {
      return { project_id: this.$route.params?.id || null }
    },
  },
  methods: {
    ...mapActions({
      getProject: 'projects/getProject',
      // list: 'jobs/getListJobs',
      getSelectStatus: 'projects/getSelectStatus',
      edit: 'projects/edit',
      deleteDoc: 'projects/deleteDoc',

    }),
    handleSearch() {
      this.currentPage = 1
      this.chargeData()
    },
    handleChangePage(page) {
      this.currentPage = page
      this.chargeData()
    },
    handlePageChange() {
      this.currentPage = 1
      this.chargeData()
    },
    submitDoc() {

      const data = this.$refs.documents.getFormData('documents')

      data.append('name', this.project.name)
      data.append('ref', this.project.ref)
      data.append('client_id', this.project.client.id)
      data.append('description', this.project.description)
      data.append("responsible_id", this.project.responsible.id)

      let id = this.project.id

      this.edit({ id, project: data })
    },
    deleteDocument(id, name) {
      this.$bvModal
        .msgBoxConfirm(`¿Estás seguro de eliminar ${name}?`, {
          bodyClass: 'bodyDelete',
          footerClass: 'footerDelete',
          okVariant: 'danger',
          okTitle: 'Eliminar',
          cancelTitle: 'Cancelar',
          cancelVariant: 'outline-danger',
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.deleteDoc({ id_project: this.project.id, id_documento: id })
          }
        })
    },
    chargeData() {
      let status = ''
      if (this.status && this.status !== null) {
        status = this.status
      }
      let orderBy = ''
      if (this.sortBy) {
        const type = (this.sortDesc) ? 'desc' : 'asc'
        orderBy = {
          column: this.sortBy,
          type,
        }
      }

      //cargar contenido

      // this.list({
      //   client: '',
      //   page: this.currentPage,
      //   per_page: this.pageLength,
      //   search: this.searchTerm,
      //   status,

      //   orderBy,
      //   project: this.$route.params.id,
      // })
    },
  },
  async created() {
    this.tabIndex = this.$route.params.tabIndex || 0
    await this.getProject(this.$route.params.id)
    await this.chargeData()
    await this.getSelectStatus({
      type: "project"
    })
    this.projectPrepare = true
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
